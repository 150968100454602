import * as Dapp from '@elrondnetwork/dapp';

export const walletConnectBridge ='https://bridge.walletconnect.org';
export const walletConnectDeepLink = 'https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet.dev&link=https://maiar.com/';
export const NFTCollection = 'ARCAD-721a04';
export const NFTContract = 'erd1qqqqqqqqqqqqqpgqjx38y0k3nyg7xtdzrl74stwlkdw35x5rq8ysnw3nqq';

export const network: Dapp.NetworkType = {
  id: "devnet",
  name: "devnet",
  egldLabel: "xEGLD",
  walletAddress: "https://devnet-wallet.elrond.com",
  apiAddress: "https://devnet-api.elrond.com",
  gatewayAddress: "https://devnet-gateway.elrond.com",
  explorerAddress: "https://devnet-explorer.elrond.com/",
};