import { Ui } from '@elrondnetwork/dapp-utils';
import * as Dapp from '@elrondnetwork/dapp';
import { getNfts } from '../../apiEndpoints';
import { getRemainingNfts } from '../../apiEndpoints';
import { useEffect, useRef, useState } from 'react';
import { NFTCollection } from '../../config';
import { shortenWalletAddress } from '../../utils';
import gears from '../../static/media/gears.png';
import balance from '../../static/media/money-bag.png';


const Staking = () => {


return (

        <div id="stakcontainer">

            <div id="work">

            <img src={gears} alt="Working"/>

            <p>Work in progress...</p>


        
            </div>

        </div>


     );
  };
  
  export default Staking;