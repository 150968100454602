import * as Dapp from '@elrondnetwork/dapp';
import MainLayout from './MainLayout';

const Unlock = () => {
  
  return (
    <MainLayout>
      <div id="test99" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 800}}>
      <div id="mainlock">
      

          <Dapp.Pages.Unlock

            callbackRoute="/dashboard"
            lead="ok"
            ledgerRoute="/ledger"
            walletConnectRoute="/walletconnect"

          />


       
      </div>
      </div>
      <div id="test98"></div>
    </MainLayout>
  );
};

export default Unlock;
