import { useHistory } from 'react-router-dom';
import * as Dapp from '@elrondnetwork/dapp';
import MainLayout from './MainLayout';

const WalletConnect = () => {
  const history = useHistory();

  const goBack = () => {
    history.push('/unlock');
  };

  return (
    <MainLayout>

      <div id="test97" style={{display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '20vh'}}>

        <div style={{maxWidth: '300',
          width: '100%',
          padding: '30',
          textAlign: 'center',
          backgroundColor: '#fff',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems:'center'}}>

          <Dapp.Pages.WalletConnect
            callbackRoute="/dashboard"
            logoutRoute="/"
            title="Maiar Login"
            lead="Scan the QR code using Maiar"
          />
          <button onClick={goBack} style={{width: 120, marginTop: 20}}>
            Back
          </button>

        </div>

      </div>

      <div id="test96"></div>
    </MainLayout>
  );
};

export default WalletConnect;
