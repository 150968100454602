import { network, NFTCollection } from './config-devnet';

export const getTransactionByHash = (txHash: string) =>
  `${network.apiAddress}/transactions/${txHash}`;

export const getTransactions = (senderAddress: string, from = 0, size = 5) =>
  `${network.apiAddress}/transactions?sender=${senderAddress}&receiver=${senderAddress}&condition=should&from=${from}&size=${size}`;

export const getNfts = (walletAddress: string) =>
  `${network.apiAddress}/accounts/${walletAddress}/nfts?search=${NFTCollection}`;

export const countNfts = (walletAddress: string) =>
  `${network.apiAddress}/accounts/${walletAddress}/nfts/count?type=NonFungibleESDT%2CSemiFungibleESDT`;

export const InfosCollection = (walletAddress: string) =>
  `${network.apiAddress}/accounts/${walletAddress}/collections/${NFTCollection}`;

export const getRemainingNfts = () =>
  `${network.apiAddress}/nfts/count?collection=${NFTCollection}`;
