import * as Dapp from '@elrondnetwork/dapp';
import SecondLayout from './SecondLayout';
import Gods from '../static/media/gods.png';
import './lore.css';

const gods = () => {
  
  return (
    <SecondLayout>
      


      <div style={{background: '#f4efe9'}}>

<div id="sectionE">
    

  <div id="contenuE">

    <h2>Encyclopedia</h2>

     <h3>Gods of Arcadia</h3>

    <div id="mountain"><img src={Gods}/></div>

    <div className="textE">
      
    <p className="firstE">In Arcadian Mythology, Gods are very important as it is in polytheist religions. The earliest and first of the gods from Arcadian Mythology were born over a billion years ago when the whole continent just appeared and was given solid reality. Those early times would be known as the Superior Era. Born in this Era were the creatures that inhabit the old Arcadian Territory, including its magical gods. These gods would be called the "Five Gods' ' or the "Ancient Gods' ' by ancient Arcadians: Arcan, Dyaus, Merida, Iorie & Atlanta.</p>

    <p>Arcan is the Earth and Life. In Arcadian Mythology, he made the whole world and the sky. He rules as the king of the Gods and the Arcadians, from the biggest Arcadian mountain. His powers are huge and mythical. They said that when there was a storm outside it was because Arcan was angry. He can provoke earthquakes and volcanoes. The Arcadians represent him as a bearded, dignified, and old man. His most prominent symbols are the sword and the eagle.</p>

    <p>Dyaus is the daughter of Arcan. She is the God of war and bravour. She represents a divine version of the heroic. The more you believe in her the glorier your combat will be. She’s the synonym of victory and excellence. Pray for her and she will show you the way, what to learn and what are the strongest qualities of the brave warrior.</p>

    <p>Merida, son of Arcan, is the God of Agriculture. So when a harvest is bad, it could be because he didn’t receive enough prayers. He is presiding over food, crops, and fertility of the Arcadian Territory in general. The farmers of the Arcadia believe that Merida looks after their agricultural lands and crops and protects the crops from dreadful diseases, thieves and animals.</p>
    <p>Lorie, sister of Arcan, is the God of the Oceans and the rivers. Hundreds of boats disappeared because of her angryness. Iorie’s home is in the Aegean Sea. Some Arcadians say that if you watch the Ocean alone at night and say her name 3 times, she could appear to you in the shape of a woman. Her most distinctive attribute is the trident.</p>
    <p>Atlanta is the God of the Dead and the King of the Underworld. No one is more hated than him. He reigns over the realm of the dead, and guards its treasures. Every dead Arcadian does have his own statue in the cemetery of The City.  But, more than anything, Atlanta is the God and the Creator of the Magic Gems.</p>


    

     

    </div>




  </div>
  
  
  
</div>

</div>


    </SecondLayout>
  );
};

export default gods;
