
import React from 'react';
import ReactDOM from 'react-dom';
import { NetworkConfig, ProxyProvider } from '@elrondnetwork/erdjs';
import App from './App';
import { network } from './config-devnet';
import reportWebVitals from './reportWebVitals';
import './main.css';

let MainComponent = (
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Sync Elrond provider
if (network?.gatewayAddress) {
  const proxyProvider = new ProxyProvider(network.gatewayAddress);
  NetworkConfig.getDefault().sync(proxyProvider);

} else {
  MainComponent = (

    <React.StrictMode>

      <div>

        <div>
          Elrond Dapp starter kit
        </div>

        <div>
          Please configure .env.local file to be able to run the app.
        </div>

        <div>

          <code>cp .env.devnet.example .env.local</code>

          <p> or </p>

          <code>cp .env.testnet.example .env.local</code>

        </div>

        <div>

          More info in the{' '}
          <a href="https://github.com/juliancwirko/cra-template-elrond-dapp/blob/main/README.md">
            README.md
          </a>{' '}

          file.
        </div>

      </div>

    </React.StrictMode>
  );
}

ReactDOM.render(MainComponent, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
