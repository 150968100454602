import * as Dapp from '@elrondnetwork/dapp';
import { useHistory } from 'react-router-dom';
import {Popover,Menu,Position} from 'evergreen-ui';
import mini from '../static/media/mini.png';
import cross from '../static/media/cross.png';
import log from '../static/media/logout.png';
import $ from 'jquery';
import { useEffect, useRef, useState } from 'react';
import dashboard from '../static/media/dashboard.png';
import wallet from '../static/media/wallet2.png';
import menu from '../static/media/menu.png';
import { shortenWalletAddress } from '../utils';
import { routes } from '../routes';
import Footer from './footer';


const MainLayout: React.FC = ({ children }) => {


  $(document).ready(function(){  

			
    $('#bouton_menu').click(function AfficheSlideDroit(){
				
      $('#rightslide').css('top','0vh');
    
    });
    
    
    $('#fermer,.fermer,#fermerslide').click(function FermeSlideDroit(){
      
    $('#rightslide').css('top','-100vh');

    });
    
  });
  

  const {address} = Dapp.useContext();
  const dappLogout = Dapp.useLogout();
  const history = useHistory();


  
  const logOut = (e: React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    dappLogout({ callbackUrl: `${window.location.origin}/`});
    history.push('/');

  };

  const unlock = () => {
  history.push('/unlock');}


  return (
    
      <Dapp.Authenticate routes={routes} unlockRoute="/unlock">

      
          <header>

          <div id="site-header">


            <div id="flexheader">

               <div id="logo" onClick={() => history.push('/')}>

                 <img src={mini}/>


               </div>

           
                <div id="nav">
				<ul  id="menu">
				
					<li className="link">
						<a href="/">Home</a>
					</li>

          <li className="link">
						<a href="/lore">The World</a>
					</li>
          <li className="link">
						<a href="/lore/towns">Cities</a>
					</li>
          <li className="link">
						<a href="/lore/heroes">Myths</a>
					</li>
          <li className="link">
						<a href="/lore/gods">Gods</a>
					</li>
          
					
				</ul>
			</div>
             
             
              <div id="right">
            {address ? (
              <div id="popover">

                <Popover
                  position={Position.BOTTOM_RIGHT}
                  content={
                    <Menu>
                      <Menu.Group>
                        <Menu.Item className="pop" onSelect={logOut}><img width={20} style={{marginRight: 20}} src={log}/>Logout</Menu.Item>
                        <Menu.Item className="pop" onSelect={unlock}><img width={20} style={{marginRight: 20}} src={dashboard}/>Dashboard</Menu.Item>
                      </Menu.Group>
                    </Menu>
                  }
                >
                  <div className="text">{shortenWalletAddress(address)}</div>
                </Popover>

              </div>

            ) : (
            

              <div className="cobutton" onClick={() => history.push('/unlock')}>
                 <img style={{marginRight: 18}} src={wallet}/>Mint
              </div>
             
            )}


              </div>

              <div id="bouton_menu"><img style={{width: 32}} src={menu}/></div>
              
          </div>

          </div>


        </header>
        

        <section>

          {children}

        </section>

        <Footer/>

<div id="rightslide">
		
	<div id="fermer">
	
		<img alt="close" src={cross}/>
	
	</div>
	
	<div>
    <ul id="menudroite">

    <li><a id="slidecollection" className="fermer" href="/">Home</a></li>
    <li className="link"><a href="/lore">Arcadia</a></li>
    <li className="link"><a href="/lore/towns">Cities</a></li>
    <li className="link"><a href="/lore/heroes">Heroes</a></li>
    <li className="link"><a href="/lore/gods">Gods</a></li>

		</ul>
  </div>

      {address ? 

    (
       <div id="popover1">

            <div  className="popover">{shortenWalletAddress(address)}</div>

            <div className="pop1" id="fermerslide" onClick={logOut}><img width={32} style={{marginRight: 20}} src={log}/>Logout</div>
            <div className="pop1" onClick={unlock}><img width={32} style={{marginRight: 20}} src={dashboard}/>Dashboard</div>
                                              
        
       </div>

    ) : (
      <div>
      <div style={{margin: 'auto'}} className="cobutton"><img style={{marginRight: 18}} src={wallet}/>Mint (soon)</div>
      </div>

    )
    }



</div>
	
      </Dapp.Authenticate >

  );
};

export default MainLayout;
