import * as Dapp from '@elrondnetwork/dapp';
import SecondLayout from './SecondLayout';
import mountain from '../static/media/mountain.png';
import './lore.css';

const lore = () => {
  
  return (
    <SecondLayout>
      
<div style={{background: '#f4efe9'}}>

<div id="sectionE">
    

  <div id="contenuE">

    <h2>Encyclopedia</h2>

     <h3>The World of Arcadia</h3>

    <div id="mountain"><img src={mountain}/></div>

    <div className="textE">
      
    <p className="firstE">Ancient texts from the Archaic period locate the Territory of Elrond Arcadia somewhere in Aretha. In this territory you could find some extensive mountain ranges in the North, and the ocean in the South. Once upon a time, the Territory of Elrond Arcadia was hosting a great city with a glorious and prosperous civilization: the Arcadians. The Arcadians were valiant people, with a strong meaning of faith and honor. Their sense of bravery was unique and even if they were very warlike, they were graceful and philosophical people. In their Society, all the Arcadians were equals and had the same rights even if some of them were Shamans, and knew a lot about medicine, others were Artisans, striking the iron, and others, Druids.</p>

    <p>More than anything, the Arcadians were loving their Gods. Praying The Gods was a central activity in everyday life, and in exchange, the Gods were watching over the Territory of Arcadia and taking care of the Arcadians, sending them Magic Gems. Magic Gems were powerful and celestial minerals and a big symbol for the Arcadians. It meant everything for them. Only the SageMan and the League Of The Sages knew how to take advantage of it. </p>

    <p>The enemies of the Arcadians were the Orcs. One day, the Orcs decided to invade the Territory Elrond Arcadia, to gain access to the country's diamond, mineral wealth, and especially, the Gems. It was an act of War. The peace was broken in the city. The army of the Orcs arrived from the North by the mountain ranges, during the night, it was a total surprise.</p>

    <p>The Arcadians asked the SageMan for some protection and help. The SageMan was a very old Arcadian, living in a mystic cave, on a little hill. His wisdom and knowledge was valuable and world-renowned. Some time ago, the SageMan started to share his knowledge with a few Arcadians. They were called the League Of The Sages. The members of that League were living hidden all around the Island of Arcadia and communicating by telepathy.</p>

    <p>The Orks had not arrived in the City yet, but the SageMan took the decision to cast one of his most powerful spells to protect the City: a Dome of Magic Barrier. In the short term, it was the best decision and it could give the Arcadians some time to prepare for the Battle.</p>


    </div>

  </div>
  

</div>

</div>
    </SecondLayout>
  );
};

export default lore;
