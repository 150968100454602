import { Card, Pane, Button } from 'evergreen-ui';
import { useHistory } from 'react-router-dom';
import * as Dapp from '@elrondnetwork/dapp';
import MainLayout from './MainLayout';

const Ledger = () => {
  const history = useHistory();

  const goBack = () => {
    history.push('/unlock');
  };

  return (
    <MainLayout>

      <div id="test95" style={{
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '20vh'}}>


        <div style={{
          maxWidth: 500,
          width: '100%',
          padding:'30',
          textAlign: 'center',
          display:' flex',
          flexDirection:'column',
          justifyContent:'center',
          alignItems: 'center',
          backgroundColor: '#fff'}}>

          <Dapp.Pages.Ledger 
          callbackRoute="/dashboard"/>

          <button style={{marginTop: 15, width: 120}} onClick={goBack}>
            Back
          </button>

        </div>
      </div>

      <div id="test94"></div>


    </MainLayout>
  );
};

export default Ledger;
