import * as Dapp from '@elrondnetwork/dapp';
import SecondLayout from './SecondLayout';
import star from '../static/media/star.png';
import './lore.css';

const heroes = () => {
  
  return (
    <SecondLayout>
      
      <div style={{background: '#f4efe9'}}>
<div id="sectionE">
    

  <div id="contenuE">

    <h2>Encyclopedia</h2>

     <h3>Myths & Legends</h3>

    <div id="mountain"><img src={star}/></div>

    <div className="textE">
      
    <p className="firstE">The first legend the oldest Arcadian living people could tell you is about the birth of the Arcadian Territory. Here is what they can tell.</p>

    <p>In the beginning there were the Big Arcan People, supernatural and sacred. All of them were living under the ground, below a big hill in the eastern world, with their prince, one magic gem, and strange creatures. It was the Superior Era. One day, a huge flood underground forced the Big Arcan People to arrive on the surface of the earth. 
    They were all very surprised about the sun, and when it was falling, during sunset, they decided to follow it, while carrying the magic gem. After many days and weeks of travel, the prince, who was the oldest Big Arcan People, understood that it was useless, and that they had to stop. Coming back to live under the ground, inside the earth was not possible, and this was going to be their new world. They could see far over a large body of water, small houses on the coast and strange creatures very similar to them. As they approached, the creatures started to throw stones on them. The prince cast a spell on them, and they all immediately became statues. Today they say that you can find those statues at the foot of The City, under the water.</p>

    <p className="firstE">The second legend is about the hill near The City called “Trois Monts”. It was a lake before, and the oldest Arcadian living people can say what happened. In Arcadian mythology, Lorie & Arcan were fighting a lot. Each of them wanted to be the most powerful God of the Arcadians. One day, Arcan was very angry and just threw a massive rock to Lorie which fell in the lake and became the hill “Trois Monts”.</p>

    <p className="firstE">Another Arcadian legend is about a sea monster called Nassy. They say that some centuries ago, a fisherman falled in love with a strange creature. He was visiting it during the night, and one night he disappeared completely. The oldest Arcadian living people say that Nassy is the result of a story between Iorie and an undersea creature. They say additionally that if you tell the story of Nassy to others, some misfortunes will happen to your family.</p>

    <p className="firstE">Do you know about the Red Bird ? It’s an old arcadian legend saying that if you see a red bird and it starts to follow you, you are in big trouble ! Indeed if you start to turn away the little bird, it will appear each time closer until you start to sleep or close your eyes, that would be the last time!</p>

    <p className="firstE">There is a legend related to music in the arcadian world. There was an old Arcadian musician called Rosenfeld who played a lot of harmonica. Sometimes, during the night, when there is a lot of wind, you can hear him playing some of his instruments, near the lighthouse and the harbor. Some people say that a happy event will happen to you even if others say that something terrible will appear in your life!</p>

 
    <p className="firstE">The legend of the magic gems starts with the beginning of the period when the Big Arcan People were living under the ground. Strange creatures were living there but all disappeared in the huge flood.

    They say that the son of Atlanta was living with them. He was a monster shaped half like a man and half like a goat. The Big Arcan People could not distinguish the day from the night. Only when one of them was starting to be tired, they were all going to sleep. 

    The rest of the time, they were praying or fishing in the small lake they had in their cave. The son of Atlanta was staying almost all the time on a rock, observing what was happening. 
    One day, one Big Arcan People trying to cast a fish disappeared in the water. After a few hours, everybody thought that he was dead. In fact he was in a nearby cave, full of diamants. Indeed, when he opened his eyes, everything was shining, he could'nt believe it. 

    The objects that were shining were kind of blue rocks, very transparent. He was very intrigued, took one, and came back in the water.
    The next morning, the Big Arcan People discovered him, unconscious in the water, with that strange rock. They woke him up, but he wasn’t able to show the way back to the cave. 
    Days and weeks passed, and the Big Arcan People became increasingly fascinated by the rock. Some of the Arcan were saying that it was speaking to them, others were watching literally for hours.</p>

    <p className="firstE">Legend of the duck. This legend says that if you want to become one day a real and proud Arcadian, you have to pass the test of the duck and eat the heart of one duck.</p>


    </div>


  </div>
  
  
  
</div>

</div>


    </SecondLayout>
  );
};

export default heroes;
