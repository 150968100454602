import { Ui } from '@elrondnetwork/dapp-utils';
import * as Dapp from '@elrondnetwork/dapp';
import { useHistory } from 'react-router-dom';
import { getNfts } from '../../apiEndpoints';
import { countNfts } from '../../apiEndpoints';
import $ from 'jquery';
import { InfosCollection } from '../../apiEndpoints';
import { useEffect, useRef, useState } from 'react';
import balance from '../../static/media/currency.png';
import home from '../../static/media/home.png';
import axios from 'axios';




const AccountTab = () => {

  const dappLogout = Dapp.useLogout();
  const history = useHistory();

  const OG = [
  'erd1qepj5jj6np9xk3qfc0g6dfmp08tg9xrpu03l35hnwdhzhk9yk5kql35c2w',
  'erd138dq8n69hjd6ysyw3mdfdle4glarf4p0esyph2r54e5ye7k50cfqqfueaj',
  'erd1knx4hu2u6zxyt2mqk9zfyf8m9pz980puw98hqsmv26d3eggxvfmsv4xlpq',
  'erd13denxmugk36lhy28e8a7q63xpqv5gr4q78d62c4hnh8rnlf5qpqq02aat0',
  'erd1rf46v7kw26vgymwnw9ljnvl8tspkp8ncaey6qpscw9cdeam2x38qjpaqpt',
  'erd1gqh79mxfr4al0gfpplvm79lxrx93tgclm3kvlfklmfgr3sp3yx8s7d3qjp',
  'erd10jqmd9fedzvmmjwr6u68lezy8stn5q2dfx9q6k73sydu7xzztc5snnwq04',
  'erd10rptglk53xqx23vxya8qpe6u78pg8czzfe9atc63vjqwwm6pp9qqj8sjuf',
  'erd1c03du4nfvhcpxupsq5hp4pc50akevlpz28kjptgdp6y84g0pt6dqg6t945',
  'erd1lgegrdtsm08cykzpg9tg6shy4r2pz8vh9lpqjupt6unqwk6828es5u9xw2',
  'erd136cn3u73022c5ecsv4s0jtkcullpwd9s0esz84y94x0gquwy0g4q4kunm2',
  'erd16gqk8pje5uj6nq4ddzg3xndtef75vzp4crmfa4xnudke92xpudjqw2rnaq',
  'erd10npr0j3mwsrjycl4055666qkude5gl66js70s325avxyjwg9255sl4mszj',
  'erd1cswlqdk7kza7z3dh7ge3c69rgctq9n0u2gx55m67wh94ypnzmr7qpnwu5v',
  'erd1dx0c59kd88tauzaja6qcx4f7ygffzj565xawsansufpudn273cuqdpejtu',
  'erd1n4r7dlq3qlln5fmdzp9w3vsk4x3sqp5gg2ayz6xqa96gq20acqfs5dtwmj',
  'erd1hxr0wf25hd4mmwq8zfxmx7x7qz2lx8s27uq5cw4z2h796q7455jq0k96f5',
  'erd1dga0qfpmam27jx40h3p2d974xsqe5tdwg8fx6ykxmqda6whs8jss226y7p',
  'erd10udk5uje734pl6wz4cdn8d9z36kfptzkn09z9pnksttqzkhgwndq9s90r6',
  'erd17ycm0wxmc8t2my8ukhn7aknq9g32wyvdxn9s28tsnrctf86mp0ysatmdhz',
  'erd1zl890854dweghll9faf67ft26965v8u20d6z63cntr9a5ykhcekqmyzcsf',
  'erd1sksdr5rp74a2t4weyyuwrcsuahhtcujc6s0h0ljvr4rsta733vaqye4uk9',
  'erd1my2pw4r7sl66d8lwvulwesd08skncvwpg5dy7q5yk5l0jn67x59sh6042k',
  'erd1ztcl4c7n98dld03k8xvftct9j0cdatrmpe7qchmpr89e9gj94qkqntx3ha',
  'erd1cyp6akxytwg8nn69p7vm9f2f3pxjvrj95zx65x4lufhaercn90nsm965dn',
  'erd1uvms684yx9frtv0x29zy5nlzrpdagar5wr2sfgengv77qsxs7q5s38quxp',
  'erd13ndphhh3x0fvwutughwe745a3zjheuez77matytghsffjtslj8asqcaf5p',
  'erd1gfxz723cdm0ve4nf0j856u4ggqjqdmqvjuuzwuwf20t2rwen4dpqgexrjn',
  'erd1gusvmk76yynmjdgp8fppt34hyuhtkly5m2dnj7vqf80ml3t7z7esz4pplu',
  'erd1us0hha9kk4fpsn9kyatwhen542z6a3lkr5q5mark76y9g0p0t6zq0zercx',
  'erd1xajqlwuldgc633cj0cxhlyyascw452dls76r3yqf8deh2440pr6s85qhwr',
  'erd1qyex2l4h5nu3q2q3mkvyh40hmftzda9ajlj3f29mg2qesmrzdzdsp82523',
  'erd109uk0alfdd0x2mp4t9lmw3ykuxh2ga3yd6xkc83x47p3n2u2lw9qcnarmv',
  'erd15sp24c9hkkxrqe0klerud9tugxnxum86a932zqm3w8d0t3kstwts7aqmmz',
  'erd18wxvqq6849y6l85mpng48vwn0hj28tkhsjk9zd8d4rre5a26whdsxxexm4',
  'erd1lkrrgxaap8e4uruhy9zeag3fhwcxv63vp8kcxdudtjjuzrrs43hsns2rcd',
  'erd1hs4sryaqqeqwqvgr5fns5r7wdffr92arv99k5dhm2pf9shx8lsaqmhwaux',
  'erd1hkh32aaltwlfe9tll88kz4cxunk9juuc7hhmqryypfqhfatv06cqtdwsfs',
  'erd1rhgw2tchzpvgyghyenj2a62vsscquv4ndfnvfxesvyq2r46k7slqzkcll3',
  'erd14ffemgdkldf8uyhyleypf6eqpud4ylpzm7w39ahlllzvl7cpgl0swmqsqt',
  'erd1fy284mp46jmhvasywtw9a233hkd50un93fxa07v2ypldt397e7es9ags00',
  'erd1f0q0flgzpjh7tyaa7l3wcdkgj4zekn2ep0y5e25lc8jkzdgc46tsnk58r4',
  'erd1v6q80anj6fg6jxvlq69f6ughpmpz5u0fz7m9kenv6v8vg7jv7jlswlyss8',
  'erd1d7gr6mt5fq7q8cs4t5kgcuu59flqypg3awx38qced696qegthyqqj067hn',
  'erd1yuf8crfqrsgqht597pfkrntxj2hkt5hrw4seza9r52hzwjj42ygssgmn4z',
  'erd17mknzc69kd4jkyhtdshxm05p6z3mlv5refer8g29g3sq857dqlgqe0hdnn',
  'erd1ggas5zumg7y57mhqmjs07jdehl07kf32uver3ystdpaspkhyztvs5y7f0v',
  'erd143n9fe0w5smgkfekm4rhd58y4du5t5w8tw27mym0w473kyxq5jcqxxcfkd',
  'erd1jgddt4dnzqskfr5xk3p5h999m9gxmf4pw4x22qp3x84j2mszhmmsxd70k5',
  'erd1vvsl0dwjydmvn2yavzr78n7fv5lsna32nnqxkvjlqzh82yv2sxsscdwrfu',
  'erd1mhth3s7g369t3ezvfn7yk3wqw3x4dgpc78qdyh6uulzs9cupjdrqdfppel',
  'erd1z8p5a6dut30rkdcl266expeyk6295w9835grs3pke8dts5ngrmmqp6yz80',
  'erd1jnezefhzswptluwhfhykqaj5vuzqmxsdw5g6e2dn2kqhlgtvmdtsrhe7da',
  'erd15dekweg0e043dxxacaszr80fvkxpdj83vd2k4n9ycq5xj5sf3nsqaclvep',
  'erd1km5f8ekwqjds84gk2z4uphkchr7hcew6ue9y9cftuhu53rg4s6vq04w3fp',
  'erd1pg9zhgkcymzltf0j3pfy4jkhavgujpkf9xn0uxxnh4gl6xta3xpqshhgkg',
  'erd140wryfqpy8ga7d0amfzldk07yujly68n9awf8qtzm9vpca3za4xsveugx7',
  'erd1uejzj5ncls8v66d9sz0qt3tgdjjxlm4kuve8t83vzrk0fgzve90shcxmmq',
  'erd1kdwv5l0vvwksh3qvffet47487t3xlh0z6qtwmmnhkkvdv0zmxzrscjhswg',
  'erd1lhzsy4evp0x8998073jj0fh5cztel58crpykwpfhf40cqq7rnpfszx6jlq',
  'erd1rc9neajhy2n3eqeyyaw4s2wtfhr7rwksdjp9feahelxf99n5x03q5fzrt6',
  'erd17278gc0z9v08a5gszejnug992v02zexr4m6xx0w8tal9p3z6a23q2q2vkx',
  'erd1j00679yy98l7628jzvrpaaftvf53gzrfq2efft8rs0y62jwaf4rqt8wrcx',
  'erd1gwf09wmuvjqcvjxexpdn6400x9xqlqt8frjn8m4enrxldlfdtz2qawu60j',
  'erd1fq3sgyqjldxah822rcf5w5reg8626a06a0m2phuhuz9hwht09fgqku7dtv',
  'erd1merpl0lekwtl7a2hfnynke8wn2m2q7eefaklxz0rxwtmja0m0c4s300afl',
  'erd1epacuwydsl4tx9de90ma6dwtck4jf7sr4pjr806fngf36xahm7gqfsd0zt',
  'erd1xacqf84dzudhyhk0engz88tthkwwdntuyulk0v3p764nae7388nqjyuanj',
  'erd1gv8w27h7l4y522v4683wfksxeycxk9ghhh3kdfc8mrc0fzj4y6xs8g7n98',
  'erd1d8caexkzyedz8dvd2t7ulutmry9a70wvez54pd8knl324g8rdncqjrw250',
  'erd16mljd4kh7zhrmr92wxuytan4ya3yq0wrhvmuuz5hhcxhvlewx90qeh3mu3',
  'erd1kj8ukfkt6sqelph577mmxxt82wh4usq8v6fwha5re6fagfv9awtskfn6zc',
  'erd17a5twsds6h6spr007nxwr8xhkqmycvflkcyzn6lsnq22h6h2lh4s0g035v',
  'erd16c02kglch5ujkxxwhelv5qmlm5d7cqkjy5hsnj4n90w0f5y8u28sh9mdwp',
  'erd1snflkycr0q2h3fwuzfxh5zu95psqjtqflfqjwht078yljjm8zsyqzrkppp',
  'erd17ww2jwqkvzzamrlyvcjmyjzw8d28070qcgttdkkugjc44jhtucaqmsu8jv',
  'erd1fnwdlmxk3n4v3mm82l8rn3elnyl3dlwvjdla7klvpdm4qtwlaxdqqfhevw',
  'erd1ck4t03pyk9zzggdf7pjf78x32l2wfmsxa0sm5ke4mw55gy7f07gsmqvt80',
  'erd1dqha52qt2en4jxp997878xdhc53py0d68jwp6ntpffjwk8ukgl8scxsys0',
  ];

  const {account, address, explorerAddress} = Dapp.useContext();

  const [nfts, setNftsList] = useState([]);
  const [count, setCount] = useState(0);
  const [infosco, setInfosco] = useState(0);
  const [smallwallet, setSmallWallet]= useState('');
  const [loading, setLoading] = useState(false);
  const mounted = useRef(true);

  const [flag, setFlag] = useState(0);



  if(OG.indexOf(address) !== -1){
    console.log("La valeur existe!")
} else {

  dappLogout({ callbackUrl: `${window.location.origin}/`});
  history.push('/');

}
  

  if (address == null)

 {

  dappLogout({ callbackUrl: `${window.location.origin}/`});
  history.push('/');
   
  }


    useEffect(() =>
  
  {

    setLoading(true);

    const fetchData = async () => {

             const resultnfts = await axios.get(getNfts(address))
             

             if (resultnfts.data != 0)

             {

             const resultnfts = await axios.get(getNfts(address))
             const resultcount =  await axios.get(countNfts(address))
             const resultstuffcount = await axios.get(InfosCollection(address))

              axios.all([resultnfts,resultcount,resultstuffcount]).then(
              axios.spread((...allData) => 
              {
                  const allDataNfts = allData[0].data;
                  const allDataCount = allData[1].data;
                  const allDataStuff = allData[2].data.count;

                 
                   setNftsList(allDataNfts)
                   setCount(allDataCount)
                   setInfosco(allDataStuff)

                   if (mounted.current) 
                   {setLoading(false);}
             
              
              })) 

              }
              
              else {

                setLoading(false);

                await axios.get(countNfts(address)).then(res => {const allnfts = res.data;
                setCount(allnfts)})

                console.log('Nothing');

              }


      };
        
  
   

    fetchData() 

    if (address != null)

    {
      const shortenWalletAddress = (address: string, charsAmount = 6) => {
        const firstPart = address.substring(0, charsAmount);
        const lastPart = address.substring(
          address.length - charsAmount,
          address.length
        );
        
        return `${firstPart}...${lastPart}`;
      };
    
      setSmallWallet(shortenWalletAddress(address))
    
    }



  }, [address])

  return (


    <div id="maincontainerstuff">

      <div id="flexcontainerstuffcard">

        <div className="stuffadress">

          <div className="titrewallet">

            Your wallet address:

          </div>

          <div className="containerbalance">

            <div><img width={48} src={home}/></div>
            <p className="text1"><a href={`${explorerAddress}accounts/${address}`}>{smallwallet}</a></p>

          </div>

        </div>




        <div className="stuffbalance">

          <div className="titrewallet">

            Your wallet balance:

          </div>
          
            
            <div className="containerbalance">

              <div><img width={48} src={balance}/></div> 
              <p className="text1"><Ui.Denominate value={account.balance} erdLabel="EGLD" /></p>
            </div>

        </div>

      </div>

      <div className="infosnfts">

         <div className="titrewallet">Infos:</div>

  
         <p>You have {count} NFTs on the Elrond Network and {infosco} Arcadia NFTs</p>
     
      
      </div>

     

      <div className="h2">My Elrond Arcadia NFTs: </div> 
 

    

 
  
      <div id="collectioncontainer">

        {loading ? (


          <div className="loader"></div>


        ) : (

       

          nfts.map((nft: any, index: number) => (

              <a className="lien" href={`${explorerAddress}nfts/${nft.identifier}`}>
                
                <div id="collectioncard" key={index}>

                  <div id="cardimg">

                   <img src={atob(nft.uris[0])} alt="NFT image" width={200}/>

                  </div>
                
                  <div className="idnft">{nft.name}</div>

                  <div id="nftidentifier">

                    {nft.identifier}

                  </div>

              </div></a>
              
          )))}

          {!loading && nfts.length === 0 && <div id="nonft">No NFT here...</div>}

      </div>


    </div>

  );

   
};

export default AccountTab;
