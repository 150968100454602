import React, { useEffect, useRef, useState } from 'react';
import previewNFT from '../../static/media/potion.png';
import { getRemainingNfts } from '../../apiEndpoints';
import * as Dapp from '@elrondnetwork/dapp';
import { NFTContract } from '../../config-devnet';
import { RawTransactionType } from '../../helpers/types';
import useNewTransaction from '../useNewTransaction';
import InputNumber from 'react-input-number';

const MintTab = () => {
  const { account, address, explorerAddress } = Dapp.useContext();
  const [remainingNFT, setRemainingNfts] = useState([]);
  const [pending, setPending] = useState(false);
  const mounted = useRef(true);
  const [value, setValue] = useState(null);



  const DROP_SIZE = 500;
  const DROP_PRICE = 0.4;

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    setPending(true);
    const fetchRemainingNfts = async () => {
      const response = await fetch(getRemainingNfts(), { signal });
      const data = await response.json();
      if (mounted.current) {
        setRemainingNfts(data);
        setPending(false);
        setValue(1);
      }
    };
    fetchRemainingNfts();
    return () => {
      mounted.current = false;
    };
  }, [address]);

  const sendTransaction = Dapp.useSendTransaction();
  const newTransaction = useNewTransaction();
  const send =
    (transaction: RawTransactionType) => async (e: React.MouseEvent) => {
      transaction.value = String(DROP_PRICE * value);
      transaction.data = `mint@${String(value).padStart(2, '0')}`;
      e.preventDefault();
      sendTransaction({
        transaction: newTransaction(transaction),
        callbackRoute: "/dashboard",
      });
    };

  const mintTransaction: RawTransactionType = {
    receiver: NFTContract,
    data: "mint",
    value: `${DROP_PRICE}`,
    gasLimit: 60000000,
  };

  const date = new Date(2022, 1, 13, 14, 0, 0);
  const dateForLaunch = Date.now().valueOf() > date.valueOf();

  return (
    
  <div>
    <div id="container">

      <div id="flexcontainer">

            <div id="boxleft">

              <img src={previewNFT} alt="loading..."/>

            </div>
    
            <div id="boxright">


                <div id="titre">A new journey begins!</div>
                <div id="price">Price: {DROP_PRICE} EGLD</div>
                <div id="mint">Total Minted: {remainingNFT}/{DROP_SIZE}</div>


                <div id="containermint">
                  
                      <div id="minttab">

                          <div id="Newbouton">

                            <InputNumber min={1} max={5} step={1} value={value ? value : 1} onChange={setValue}/>

                          </div>
                          <div id="mintbutton">

                            <button onClick={send(mintTransaction)}>

                              Mint Now

                            </button>

                          </div>

                      </div>

                      <div id="max">

                        
                        <p>Max 5 NFTs per Transaction</p>
                        <p>Max 15 NFTs per wallet</p>

                      </div>

                </div>

            </div>

      </div>
    

    

    </div>
    
  </div>
    
  );
};

export default MintTab;
