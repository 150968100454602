import * as Dapp from '@elrondnetwork/dapp';
import SecondLayout from './SecondLayout';
import mountain from '../static/media/mountain.png';
import './lore.css';

const lore = () => {
  
  return (
    <SecondLayout>
      


  <div style={{background: '#f4efe9'}}>

<div id="sectionE">
    

  <div id="contenuE">

    <h2>Encyclopedia</h2>

     <h3>Cities of Arcadia</h3>

    <div id="mountain"><img src={mountain}/></div>

    <div id="textE">
      
<p>Coming soon...</p>

    </div>




  </div>
  
  
  
</div>

</div>

    </SecondLayout>
  );
};

export default lore;
