import MainLayout from './MainLayout';
import { useHistory } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import feu from '../static/media/feu.png';
import roadmap from '../static/media/roadmap.png';
import pause from '../static/media/pause.png';
import tresor from '../static/media/tresor.png';
import gate from '../static/media/gate.png';
import token from '../static/media/token.png';
import hero from '../static/media/hero.png';
import piece from '../static/media/piece.png';
import tavern from '../static/media/tavern.png';
import sage from '../static/media/sage.png';
import rubis from '../static/media/rubis.png';
import potion from '../static/media/potion.png';
import tower from '../static/media/tower.png';
import merchant from '../static/media/merchant.png';
import towerdefense from '../static/media/towerdefense.png';
import forge from '../static/media/forge.png';
import house from '../static/media/house.png';
import fonds from '../static/media/2.png';
import unknow from '../static/media/unknow.png';
import fonds1 from '../static/media/3.png';
import fonds2 from '../static/media/4.png';
import fonds3 from '../static/media/5.png';
import fonds4 from '../static/media/6.png';
import $ from 'jquery';
import * as Dapp from '@elrondnetwork/dapp';

const Home = () => {

  $(document).ready(function(){  

  const cloud = document.getElementById('cloud');
  const dessin = document.getElementById('dessin');
  const gem = document.getElementById('gem');	
  const sgem = document.getElementById('sgem');

  window.addEventListener('scroll', scrolling)


  function scrolling () {
 
  const value =  window.scrollY;

  dessin.style.top = value/2 + 'px';
  cloud.style.top = value/2.5 + 'px';
  gem.style.top = value/4 + 'px';
  sgem.style.top = value/3.5 + 'px';



  }})

  const history = useHistory();
  const { account, address, explorerAddress } = Dapp.useContext();


  return (


    <MainLayout>
      
    
      <div id ="collection">

        <img src={fonds} id="fonds"/>
        <img src={fonds1} id="cloud"/>
        <img src={fonds3} id="gem"/>
        <img src={fonds4} id="sgem"/>
        <img src={fonds2} id="dessin"/>


            <div className="box">

            </div>

      </div>

    <div id="arcadia">

      <div id="arcadia1">


              <div id="arcadia15">

                 <img style={{width: '80%'}} src={feu}/>

              </div>

            <div id="arcadia2">

                  <h2>Welcome to Arcadia!</h2>

  
  
                  <p>A new universe opens up to players. Travel the world of <b>Arcadia</b> and discover its mysteries and wild lands. Fight, collect, and Earn!</p>
                  <p>Elrond Arcadia is an NFT play to earn project. Our whitepaper is available!</p>
  
              <div id="white">

                 
                   <a href="https://elrondarcadia.gitbook.io/elrondarcadia"><div className="bouton">Whitepaper</div>
                   </a>


              </div>

            </div>

      </div>

    </div>


<div id="nftcontainer">

<div id="welcome">

  <div id="welcometext">

    <h2 style={{color: 'white'}} >Play and Earn</h2>
    
    <p>Earn <b>$ARCADIA</b> Tokens by completing quests!</p>
    
    <p>In our future game players earn cryptocurrencies (ESDT Token) according to his involvement.
    Players can also earn <b>$ARCADIA</b> by participating in certain game events. Get involved in the universe and earn tokens!</p>

  </div>

    <div id="welcomeimage">

      <div id="mob1">

        <img src={tresor}/>
        <img className="tresor" src={piece}/>
    
      </div>


    </div>


</div>

</div>


<div id="staking">

        <div className="box"></div>

          <div id="stakingcontainer">

              <div id="stakingimage">

                <img src={rubis}/>
  
              </div>

              <div id="stakingtext">

                    <h2>NFT Staking</h2>
    
                    <p>Lock up your NFTs within our decentralized application and earn tokens safely!</p>
    
                     <p>Staking your NTFs is a simple way to get passive income over time. Within our project, certain NFTs allow you to earn more rewards (Bonus)</p>

              </div>

          </div>






</div>

<div id="pause">

<div className="box"></div>


</div>



<div id="look">

<div id="containerlook">


    <h2>The World of Arcadia</h2> 
 

    <div id="lore">

      <div id="loreimage">

      </div>

      <div id="loretext">

          <h2>Lore Game</h2>

        <p>The kingdom of Arcadia is a vast world with a rich history.</p>

        <p>If you want to know more then we invite you to consult the Arcadia encyclopedia</p>

        <a href="/lore"><div id="ency">Encyclopedia</div></a>

      </div>

    </div>
</div></div>

<div id="collec">
  
<div id="containercollec">

  <div id="diaporama">

    <h2>Our NFT collections</h2> 

    <div id="titrecollec">FONDATION</div> 

    <div id="second">

        <div className="nft">

          <img src={house}/>

        </div>

        <div className="nft">

          <img  src={potion}/>
     
        </div>

        <div className="nft">

            <img  src={forge}/>
    
        </div>

        <div className="nft">

            <img  src={tower}/>

        </div>

        <div className="nft">

         <img  src={tavern}/>

        </div>
        
        <div className="nft">

         <img  src={merchant}/>

        </div>

        
        <div className="nft">

         <img src={towerdefense}/>

        </div>

        
        <div className="nft">

         <img  src={gate}/>

        </div>

        
        <div className="nft">

         <img src={sage}/>

        </div>

    </div>
  
</div>

</div>

</div>


<div id="token">

<div id="containertoken">


  <div id="containertoken2">

    <div id="tokenimage">
      
      <img style={{width: '100%'}} src={token}/>
    
    </div>

    <div id="tokentxt"> 

      <h2>Arcadia Token</h2> 

      <p>It is possible to obtain our token in different ways. The donut chart displays the different sources well.</p>

      <p>This token is the beating heart of the Elrond Arcadia project. Thanks to this currency the player will be able to purchase the futur collections of the project. Indeed, the next collections will be only accessible by our currency.</p>
      <p>We will organize private and public sales in order to distribute the tokens.</p>

      <p><b>Hard cap: 200,000,000 tokens</b></p>
      </div>

  </div>
</div>

</div>


<div id="roadmap">

<div id="roadmapcontainer">

<h2>Roadmap V1</h2>

<div id="road">

<img  src={roadmap}/>

</div>

</div>

</div>


<div id="team">

    <div id="teamcontainer">

      <h2>Our Heroes</h2>

      <div id="hero">

      <img  src={hero}/>

      </div>

    </div>

</div>

<div className="box2"></div>

    </MainLayout>

  );
};

export default Home;
