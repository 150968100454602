import React from 'react';
import { useState } from 'react';
import * as Dapp from '@elrondnetwork/dapp';
import MainLayout from '../MainLayout';
import MintTab from './MintTab';
import { useHistory } from 'react-router-dom';
import AccountTab from './AccountTab';
import { routes } from '../../routes';
import Staking from './Staking';


const Dashboard = () => {

  const [selectedTabs, setSelectedTabs] = useState(1);

  const selectedTab = (index) => {setSelectedTabs(index)};


  return (
<>
    <MainLayout>

      <div id="dashboardmenu">

        <div id="tablist">

           <div className={selectedTabs === 1 ? 'tabs active-tabs' : 'tabs'} onClick={() => selectedTab(1)}>My NFTs
           </div>

           <div className={selectedTabs === 2 ? 'tabs active-tabs' : 'tabs'} onClick={() => selectedTab(2)}>Mint
           </div>

           <div className={selectedTabs === 3 ? 'tabs active-tabs' : 'tabs'} onClick={() => selectedTab(3)}>Staking
           </div>
         
        </div>

        <div> {selectedTabs === 1 && <AccountTab/>}</div>

        <div> {selectedTabs === 2 && <MintTab/>}</div>

        <div> {selectedTabs === 3 && <Staking/>}</div>

      </div>

      <div id="battle"></div>

   </MainLayout>

</>
    
  );
};
export default Dashboard;
